<template>
  <div class="flex justify-center align-center h-screen bg-gray-100">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout'
}
</script>