import { createRouter, createWebHashHistory } from "vue-router";
import LoginPage from "@/views/LoginPage.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {layout: 'auth'},
    component: LoginPage,
  },
  {
    path: "/",
    name: "home",
    meta: {layout: 'main'},
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {layout: 'main'},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutJobView.vue"),
  },
  {
    path: "/view",
    name: "view",
    meta: {layout: 'main'},
    component: () =>
      import(/* webpackChunkName: "view" */ "../views/FirstResultView.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {layout: 'main'},
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
  },
  {
    path: "/vacancies",
    name: "vacancies",
    meta: {layout: 'main'},
    component: () =>
      import(/* webpackChunkName: "vacancies" */ "../views/VacanciesView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
