import { createStore } from "vuex";
import {
  vacancyNames,
  regionsVariables,
  ITCompanys,
  timeVariables,
  remoteVacancyVariables,
  jobPlatform,
  sanitaizerFilters
} from "../utils/config";

export default createStore({
  modules: {
    mainState: {
      namespaced: true,
      state: {
        file: [],
        dashboard: [],
        currentStep: 1,
        currentView: "rate-table-skills",
        currentVacancy: 0,
        jobDescription: "",
        authParams: {
          email: "",
          pass: ""
        },
        vacancies: [],
        vacanciesSortBy: "compatibility",
        vacanciesFilters: {
          search: null,
          dates: null,
          remote: null,
          region: null,
          platform: null
        },
        creditsCount: 3000
      },
      getters: {
        getSortedVacancies: (state) => {
          if (state.vacanciesSortBy === "compatibility") {
            return state.vacancies.sort((a, b) => {
              return b.compatibility - a.compatibility;
            });
          }
        },
        getCountVacancies: (state, getters) => {
          return getters.getFilteredVacancies.length
        },
        getFilteredVacancies: (state, getters) => {
          let result = [...getters.getSortedVacancies];
          for (let key in state.vacanciesFilters) {
            const val = state.vacanciesFilters[key]?.value;

            if (!!val && val !== "any") {
              result = result.filter(vac => vac[sanitaizerFilters[key]].includes(val));
            }
          }

          return result;
        }
      },
      mutations: {
        ADD_TO_DASHBOARD(state, name) {
          state.dashboard = [name];
        },
        SET_UPLOADED_FILE(state, data = []) {
          state.file = [...state.file, ...data];
        },
        REMOVE_UPLOADED_FILE(state, i) {
          state.file.splice(i, 1);
        },
        PURCHASE_ITEM(state, cost) {
          state.creditsCount -= cost;
        },
        SET_CUR_STEP(state, step) {
          state.currentStep = step;
        },
        SET_CURRENT_VIEW(state, view) {
          state.currentView = view;
        },
        SET_CURRENT_VACANCY_ITEM(state, vacancyIdx) {
          state.currentVacancy = vacancyIdx;
        },
        SET_EMAIL(state, email) {
          state.authParams.email = email;
        },
        SET_PASS(state, pass) {
          state.authParams.pass = pass;
        },
        GENERATE_VACANCIES(state) {
          if (state.vacancies.length) {
            return
          }
          function randomizeItem(arr) {
            return arr[Math.floor(Math.random() * arr.length)];
          }

          for (let i = 0; i < 65; i += 1) {
            const item = {
              name: randomizeItem(vacancyNames),
              company: randomizeItem(ITCompanys),
              location: `${randomizeItem(regionsVariables)} ${randomizeItem(remoteVacancyVariables)}`,
              compatibility: Math.floor(Math.random() * 50) + 35,
              loadTime: `${Math.ceil(Math.random() * 7)} ${randomizeItem(timeVariables)}`,
              jobExchange: randomizeItem(jobPlatform)
            };

            state.vacancies.push(item);
          }
        },
        CLEAR_VACANCIES_FILTERS(state) {
          state.vacanciesFilters = {
            search: null,
            dates: null,
            remote: null,
            region: null,
            platform: null
          };
        },
        SET_SORT_VACANCY_BY(state, sortBy = "compatibility") {
          state.vacanciesSortBy = sortBy;
        },
        UPDATE_JOB_DESC(state, desc = "") {
          state.jobDescription = desc;
        },
        CLEAR_JOB_DESC(state) {
          state.jobDescription = "";
        },
        LOGOUT(state) {
          state.authParams = {
            email: "",
            pass: ""
          };
          localStorage.removeItem("authParams");
        }
      }
    }
  }
});
