const datePostedVariables = [
  {
    text: "Any time",
    value: "any"
  },
  {
    text: "Past 24 hours",
    value: "hours"
  },
  {
    text: "Past week",
    value: "days"
  },
  {
    text: "Past month",
    value: "week"
  }
];

const remoteVariables = [
  {
    text: "Any option",
    value: "any"
  },
  {
    text: "Remote",
    value: "Remote"
  },
  {
    text: "On-site",
    value: " "
  }
];

const platformVariables = [
  {
    text: "Any platform",
    value: "any"
  },
  {
    text: "LinkedIn",
    value: "LinkedIn"
  },
  {
    text: "Indeed",
    value: "Indeed"
  }
];

const regionsVariables = Object.freeze([
  "Albania", "Andorra", "Armenia", "Austria", "Azerbaijan", "Belarus", "Belgium", "Bosnia and Herzegovina", "Bulgaria",
  "Croatia", "Cyprus", "Czech Republic", "Denmark", "Estonia", "Finland", "France", "Georgia", "Germany", "Greece",
  "Hungary", "Iceland", "Ireland", "Italy", "Kazakhstan", "Kosovo", "Latvia", "Liechtenstein", "Lithuania", "Luxembourg",
  "Malta", "Moldova", "Monaco", "Montenegro", "Netherlands", "North Macedonia", "Norway", "Poland", "Portugal", "Romania",
  "Russia", "San Marino", "Serbia", "Slovakia", "Slovenia", "Spain", "Sweden", "Switzerland", "Turkey", "Ukraine",
  "United Kingdom"]);

const ITCompanys = Object.freeze([
  "Zerosum", "Lucid Games Ltd", "Inovola", "TalentHub", "Rage Quit Games", "PixelForge Studios", "DreamByte Games",
  "Nebula Interactive", "CodeCrafters", "GameMakers", "VirtualRealms", "Infinity Loop Games", "EchoGames", "NovaPlay",
  "Quantum Developments", "Skyward Interactive", "Mythic Mind Games", "Phantom Pixel", "Arcane Arts", "ByteBlitz Studios",
  "Sphere Games", "Catalyst Creations", "Pixel Pioneers", "Digital Dreams", "Unity Wizards", "EpicQuest Games",
  "Mirage Studios", "Vanguard Gaming", "RebelWorks", "TerraTech Studios", "Mystic Makers", "NeonByte", "Apex Innovations",
  "Spectral Games", "Infinite Odyssey", "CyberCraft Studios", "WizardWorks Gaming", "Rogue Element Games",
  "StarForge Studios", "Firebird Games"]);

const vacancyNames = Object.freeze([
  "Senior Game Developer", "Senior Unity 3D Developer", "Unity Developer", "Senior Unity Developer", "Senior Gameplay Programmer"
]);

const timeVariables = Object.freeze([
  "hours ago", "days ago", "weeks ago", "months ago"
]);

const remoteVacancyVariables = Object.freeze([
  " ", "(Remote EU zone)", "(Remote)"
]);

const jobPlatform = Object.freeze([
  "LinkedIn", "Indeed"
]);

const sanitaizerFilters = Object.freeze({
  search: "name",
  dates: "loadTime",
  remote: "location",
  region: "location",
  platform: "jobExchange"
});

export {
  datePostedVariables,
  remoteVariables,
  platformVariables,
  regionsVariables,
  ITCompanys,
  vacancyNames,
  remoteVacancyVariables,
  timeVariables,
  jobPlatform,
  sanitaizerFilters
};