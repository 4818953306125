<template>
  <div class="drawer drawer-open">
    <input id="my-drawer-2" type="checkbox" class="drawer-toggle" />
    <div class="drawer-content">
      <div class="flex flex-col items-center justify-between max-h-screen">
        <HeaderApp />
        <router-view />
        <FooterApp />
      </div>
    </div>
    <div class="drawer-side text-white">
      <label for="my-drawer-2" aria-label="close sidebar" class="drawer-overlay"></label>

      <div class="flex flex-col justify-between min-h-full bg-indigo-700 p-4">
        <div>
          <div class="flex gap-2 cursor-pointer" @click="$router.push('/vacancies')">
            <!--            <LogoSVG />-->
            <div>
              <img src="../assets/images/Logo1.png" alt="logo" class="w-[210px]" />
            </div>
          </div>
          <div
            class="btn btn-sm bg-base-200 text-indigo-700 flex gap-3 justify-start border-none btn-block mt-10 relative"
            @click="$router.push('/dashboard')">
            <ResumeSVG color="#4338CA" />
            My Dashboard
            <div v-if="dashboard.length"
                 class="badge bg-green-300 badge absolute top-1.5 right-2 text-indigo-600 text-sm">
              {{ dashboard.length }}
            </div>
          </div>
          <div v-if="currentStep !== 1"
               class="btn btn-sm bg-base-200 text-indigo-700 flex gap-3 justify-start border-none btn-block mt-2 relative"
               @click="$router.push('/vacancies')">
            <VacancyListSVG color="#4338CA" />
            Suitable Vacancies
            <div class="badge bg-green-300 badge absolute top-1.5 right-2 text-indigo-600 text-sm">
              {{ vacancies.length }}
            </div>
          </div>
          <div v-if="![1, 2].includes(currentStep)"
               class="btn btn-sm bg-base-200 text-indigo-700 flex gap-3 justify-start border-none btn-block mt-2 relative">
            <AISVG />
            AI Interview
          </div>
          <div v-if="![1, 2].includes(currentStep)"
               class="btn btn-sm bg-base-200 text-indigo-700 flex gap-3 justify-start border-none btn-block mt-2 relative">
            <LetterSVG />
            Cover Letter
          </div>
          <div class="">
            <ul class="menu w-full px-0">
              <li>
                <div class="menu-title flex gap-3 px-3 items-center">
                  <ReviewSVG />
                  <div class="text-green-300">Review my CV</div>
                </div>
                <ul v-if="file?.length" class="reviewMenu ml-5">
                  <li>
                    <a class="text-gray-200">
                      Improve for vacancy
                    </a>
                  </li>
                  <li>
                    <a class="text-gray-200">
                      Check CV
                    </a>
                  </li>
                  <li>
                    <a class="text-gray-200">
                      Download CV
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div class="stats shadow bg-gray-100">
            <div class="stat">
              <div class="stat-title mb-2">Your available AI credits</div>
              <div class="flex justify-between items-center">
                <div class="flex gap-1 justify-center items-center">
                  <CoinSVG color="#4338CA" />
                  <div class="text-xl">{{ creditsCount }}</div>
                </div>
                <button class="btn btn-sm btn-success btn-square btn-outline btn-disabled">
                  <CartSVG color="#10B981" />
                </button>
              </div>
            </div>
          </div>
          <div class="flex justify-between gap-4 mt-4">
            <div>
              <div class="text-yellow-300">Go Logo PRO</div>
              <div class="text-sm">Get all features</div>
            </div>
            <button class="btn btn-info btn-outline">
              UPGRADE
            </button>
          </div>
          <button class="btn btn-block btn-ghost flex gap-2 mt-4">
            <LogoutSVG />
            <div @click="logOut">LOG OUT</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LogoSVG from "@/assets/svg/logo-svg.vue";
import CoinSVG from "@/assets/svg/coin-svg.vue";
import LogoutSVG from "@/assets/svg/loguot-svg.vue";
import ReviewSVG from "@/assets/svg/review-svg.vue";
import HeaderApp from "@/components/HeaderApp.vue";
import FooterApp from "@/components/FooterApp.vue";

import { createNamespacedHelpers } from "vuex";
import CartSVG from "@/assets/svg/cart-svg.vue";
import ResumeSVG from "@/assets/svg/resume-svg.vue";
import VacancyListSVG from "@/assets/svg/vacancy-list.vue";
import AISVG from "@/assets/svg/ai.vue";
import LetterSVG from "@/assets/svg/letter-svg.vue";

const {
  mapState,
  mapMutations
} = createNamespacedHelpers("mainState");

const hashMap = {
  "/": 1,
  "/vacancies": 2,
  "/view": 3,
  "/dashboard": 0,
  "/about": 0
};
export default {
  name: "MainLayout",
  components: { LetterSVG, AISVG, VacancyListSVG, ResumeSVG, CartSVG, FooterApp, HeaderApp, ReviewSVG, LogoutSVG, CoinSVG },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      file: "file",
      vacancies: "vacancies",
      currentStep: "currentStep",
      creditsCount: "creditsCount",
      dashboard: "dashboard"
    })
  },
  methods: {
    ...mapMutations({
      SET_CUR_STEP: "SET_CUR_STEP",
      LOGOUT: "LOGOUT"
    }),
    async logOut() {
      this.LOGOUT();
      await this.$router.push("/login");
    }
  },
  watch: {
    ["$route.path"](val) {
      this.SET_CUR_STEP(hashMap[val]);
    }
  }
};
</script>

<style scoped>
.reviewMenu:before {
  background-color: #6EE7B7;
  opacity: 1;
}
</style>
