<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import AuthLayout from "@/layout/AuthLayout.vue";
import MainLayout from "@/layout/MainLayout.vue";
import { createNamespacedHelpers } from "vuex";

const {
  mapMutations
} = createNamespacedHelpers("mainState");
export default {
  data() {
    return {};
  },
  async mounted() {
    const authParams = JSON.parse(localStorage.getItem('authParams'));
    if (!authParams?.email || !authParams?.pass) {
      await this.$router.push('/login')
    } else {
      this.SET_EMAIL(authParams?.email);
      this.SET_PASS(authParams?.pass)
      await this.$router.push('/')
    }
  },
  methods: {
    ...mapMutations({
      SET_EMAIL: "SET_EMAIL",
      SET_PASS: "SET_PASS"
    }),
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'auth') + '-layout'
    }
  },
  components: {
    AuthLayout,
    MainLayout
  },
};
</script>

<style>
/* Define the scrollbar style */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Define the thumb style */
::-webkit-scrollbar-thumb {
  background: #9ca3af;
  border-radius: 5px;
}

/* Define the track style */
::-webkit-scrollbar-track:horizontal {
  background-color: white;
  box-shadow: inset 0 0 2px 2px gainsboro;
}
</style>
