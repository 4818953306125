<template>
  <header class="navbar bg-transparent flex justify-between items-center gap-2">
    <div class="w-64"></div>
    <div class="h-[66px]">
      <ul class="steps" v-show="currentStep !== 0">
        <li class="step" v-for="(step, idx) in steps" :key="idx" :class="isCurrentStep(idx) && 'step-primary'">
          {{ step }}
        </li>
      </ul>
    </div>
    <div>
      <div class="bg-gray-100 text-gray-600 rounded-xl cursor-pointer hover:bg-gray-200 h-10 mr-2 px-6 py-2">{{ authParams?.email }}</div>
      <div class="dropdown dropdown-end">
        <label tabindex="0" class="btn btn-ghost btn-circle avatar">
          <div class="w-12 rounded-full">
            <img src="../assets/images/photo-1534528741775-53994a69daeb.jpg" alt="avatar account"/>
          </div>
        </label>
        <ul tabindex="0" class="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
          <li>
            <a class="justify-between">
              Profile
              <span class="badge">New</span>
            </a>
          </li>
          <li><a>Settings</a></li>
          <li><a @click="logOut">Logout</a></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapState,
  mapMutations,
} = createNamespacedHelpers('mainState');
export default {
  name: 'HeaderApp',
  data() {
    return {
      steps: Object.freeze([
        'Upload resume',
        'Choose job',
        'Upgrade resume',
        'Choose template',
        'Prepare to interview'
      ])
    }
  },
  methods: {
    ...mapMutations({
      LOGOUT: 'LOGOUT',
    }),
    async logOut() {
      await this.LOGOUT();
      this.$router.push('/login')
    },
    isCurrentStep(idx) {
      return this.currentStep >= idx + 1
    }
  },
  computed: {
    ...mapState({
      currentStep: "currentStep",
      authParams: 'authParams'
    })
  },
}
</script>