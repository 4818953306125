<template>
  <div class="hero min-h-screen w-1/2">
    <div class="hero-content flex-col w-full">
      <div class="text-center">
        <h1 class="text-5xl font-bold">Login now!</h1>
      </div>
      <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
        <div class="card-body">
          <div class="form-control">
            <label class="label">
              <span class="label-text">Email</span>
            </label>
            <input v-model="emailModel" type="email" placeholder="email" class="input input-bordered" />
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">Password</span>
            </label>
            <input v-model="passModel" type="password" placeholder="password" class="input input-bordered" />
            <label class="label">
              <span class="text-indigo-700 text-xs">Forgot password?</span>
            </label>
          </div>
          <div class="form-control mt-6">
            <button
              :disabled="!authParams.email || !authParams.pass"
              @click="saveAuthParams"
              class="btn btn-primary">
              <span :class="isLoading && 'loading loading-spinner'">Login</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const {
  mapState,
  mapMutations
} = createNamespacedHelpers("mainState");
export default {
  name: 'LoginPage',
  data() {
    return {
      isLoading: false,
      timeout: null,
    }
  },
  async mounted() {
    if (this.authParams.email) {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapState({
      authParams: "authParams"
    }),
    emailModel: {
      get() {
        return this.authParams.email
      },
      set(val) {
        this.SET_EMAIL(val)
      }
    },
    passModel: {
      get() {
        return this.authParams.pass
      },
      set(val) {
        this.SET_PASS(val)
      }
    }
  },
  methods: {
    ...mapMutations({
      SET_EMAIL: "SET_EMAIL",
      SET_PASS: "SET_PASS"
    }),
    saveAuthParams() {
      this.isLoading = true
      localStorage.setItem('authParams', JSON.stringify(this.authParams));
      this.timeout = setTimeout(async () => {
        this.isLoading = false
        await this.$router.push('/')
      }, 1500)
    },
  },
  beforeUnmount() {
    clearTimeout(this.timeout)
  }
}
</script>